/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Slider from 'react-slick';

class BlogList extends Component {
    render(){
        const settings = {
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: false,
            infinite: true,
            dots: true,
            autoplay: true,
            autoplaySpeed: 2000,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        infinite: true,
                        slidesToScroll: 1,
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToScroll: 1,
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 580,
                    settings: {
                        slidesToScroll: 1,
                        slidesToShow: 1
                    }
                }
            ]
        }
        return (
            <section id="blog_part">
                <div className="container">
                    <div className="section_head blog_head text-center">
                        <h2>Recent Posts.</h2>
                        <p>Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium.</p>
                    </div>
                    <div className="row">
                        <Slider {...settings} className="blog_content_part col-12">
                            {/* slick slider */}
                            <div className="slider-item">
                                <div className="blog_content">
                                <div className="blog_img_part">
                                    {/* slick slide inner */}
                                    <div className="img_slide">
                                    <div className="blog_img">
                                        <img src="images/work-1.jpg" alt="img" />
                                    </div>
                                    </div>
                                    <div className="blog_img_inner">
                                    <div className="blog_date">
                                        <h4>30</h4>
                                        <p>Sep.</p>
                                    </div>
                                    <a href="/"><i className="fa fa-link" /></a>
                                    </div>
                                </div>
                                <div className="blog_des">
                                <a href="#" className="title"><h3>Lorem ipsum dolor sit amet</h3></a>
                                    <p>Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium.</p>
                                    <a href="#" className="read_more">Read More<i className="fa fa-arrow-right" /></a>
                                </div>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="blog_content">
                                    <div className="blog_img_part">
                                        {/* slick slide inner */}
                                        <div className="img_slide">
                                        <div className="blog_img">
                                            <img src="images/work-2.jpg" alt="img" />
                                        </div>
                                        </div>
                                        <div className="blog_img_inner">
                                        <div className="blog_date">
                                            <h4>31</h4>
                                            <p>sep.</p>
                                        </div>
                                        <a href="#"><i className="fa fa-link" /></a>
                                        </div>
                                    </div>
                                    <div className="blog_des">
                                        <a href="#" className="title"><h3>Lorem ipsum dolor sit amet</h3></a>
                                        <p>Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium.</p>
                                        <a href="#" className="read_more">Read More<i className="fa fa-arrow-right" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="blog_content">
                                <div className="blog_img_part">
                                    {/* slick slide inner */}
                                    <div className="img_slide">
                                    <div className="blog_img">
                                        <img src="images/work-3.jpg" alt="img" />
                                    </div>
                                    </div>
                                    <div className="blog_img_inner">
                                    <div className="blog_date">
                                        <h4>13</h4>
                                        <p>Oct.</p>
                                    </div>
                                    <a href="#"><i className="fa fa-link" /></a>
                                    </div>
                                </div>
                                <div className="blog_des">
                                    <a href="#" className="title"><h3>Lorem ipsum dolor sit amet</h3></a>
                                    <p>Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium.</p>
                                    <a href="#" className="read_more">Read More<i className="fa fa-arrow-right" /></a>
                                </div>
                                </div>
                            </div>
                            <div className="slider-item">
                            <div className="blog_content">
                            <div className="blog_img_part">
                                {/* slick slide inner */}
                                <div className="img_slide">
                                <div className="blog_img">
                                    <img src="images/work-3.jpg" alt="img" />
                                </div>
                                </div>
                                <div className="blog_img_inner">
                                <div className="blog_date">
                                    <h4>23</h4>
                                    <p>Nov</p>
                                </div>
                                <a href="#"><i className="fa fa-link" /></a>
                                </div>
                            </div>
                            <div className="blog_des">
                                <a href="#" className="title"><h3>Lorem ipsum dolor sit amet</h3></a>
                                <p>Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium.</p>
                                <a href="#" className="read_more">Read More<i className="fa fa-arrow-right" /></a>
                            </div>
                            </div>
                        </div>
                        </Slider>
                    </div>
                </div>
            </section>
        )
    }
}

export default BlogList