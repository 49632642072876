import React, { Component } from 'react';
import Slider from "react-slick";
import SlideImg1 from  '../images/banner-2.jpg';
import SlideImg2 from  '../images/banner-1.jpg';
import SlideImg3 from  '../images/banner-3.jpg';
function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <i
            className={className, 'fa fa-angle-left banner_arrow_left'}
            style={{ ...style,}}
            onClick={onClick}
        />
    );
}
function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <i
            className={className, 'fa fa-angle-right banner_arrow_right'}
            style={{ ...style,}}
            onClick={onClick}
        />
    );
}

class Banner extends Component {
    render(){
        const sliderSettings = {
            slidesToScroll: 1,
            slidesToShow: 1,
            speed: 1000,
            fade: true,
            infinite: true,
            arrows: true,
            dots: true,
            autoplay: true,
            prevArrow: <PrevArrow />,
            nextArrow: <NextArrow />,
            cssEase: 'linear'
          };
        return(
            <section id="banner_part">
                <Slider {...sliderSettings} className="slide_active">
                    <div>
                        <div className="banner_item" style={{backgroundImage: `url(${SlideImg1})`}}>
                            <div className="container text-center">
                                <div className="row">
                                    <div className="banner_text">
                                    <h3>WELCOME on <span>spirit</span></h3>
                                    <h4><span>SPIRIT</span> UNIQUE DESIGN WE LOVE IT.</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet porro eaque assumenda consequatur asperiores laboriosam debitis, explicabo.</p>
                                    <a href="/" className="multi_button">Know More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="banner_item" style={{backgroundImage: `url(${SlideImg2})`}}>
                            <div className="container text-center">
                            <div className="row">
                                <div className="banner_text">
                                <h3>WELCOME on <span>spirit</span></h3>
                                <h4><span>SPIRIT</span> UNIQUE DESIGN WE LOVE IT.</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet porro eaque assumenda consequatur asperiores laboriosam debitis, explicabo.</p>
                                <a href="/" className="multi_button">Know More</a>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="banner_item" style={{backgroundImage: `url(${SlideImg3})`}}>
                            <div className="container text-center">
                            <div className="row">
                                <div className="banner_text">
                                <h3>WELCOME on <span>spirit</span></h3>
                                <h4><span>SPIRIT</span> UNIQUE DESIGN WE LOVE IT.</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet porro eaque assumenda consequatur asperiores laboriosam debitis, explicabo.</p>
                                <a href="/" className="multi_button">Know More</a>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </section>
        )
    }
};

export default Banner