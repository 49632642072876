import React, { Component } from 'react';
let filterr = () => {
    let filtererLi = document.querySelectorAll('.simplefilter li');
    let filterItem = document.querySelectorAll('.filtr-item');
    filtererLi.forEach((el, i) => {
        el.addEventListener('click', (e) => {
            for (i = 0; i < filtererLi.length; i++){
                filtererLi[i].classList.remove('active');
            }
            el.removeAttribute('class', 'active')
            el.setAttribute('class', 'active')
            let filterId = el.dataset.filter
            filterItem.forEach((eli, index) => {
                let catIds = eli.dataset.category
                eli.style.display = 'block'
                if (!catIds.includes(`${filterId}`) && `${filterId}` !== 'all'){
                    eli.style.display = 'none'
                }
            })
        })
    })
}

class WorkList extends Component {
    componentDidMount(){
        filterr()
    }

    render(){
        return (
            <section id="work_part">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                        <div className="section_head work_head text-center">
                            <h2><span>our</span> protfolio</h2>
                            <p>Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium.</p>
                        </div>
                        <div>
                            <ul className="simplefilter work_list">
                                <li className="active" data-filter="all">All Project</li>
                                <li data-filter={1}>Latest</li>
                                <li data-filter={2}>Design</li>
                                <li data-filter={3}>Development</li>
                                <li data-filter={4}>PHP</li>
                                <li data-filter={5}>Apps</li>
                            </ul>
                        </div>
                        <div className="filtr-container row mx-0">
                            <div className="col-sm-6 col-lg-4 filtr-item" data-category="1, 5">
                            <div className="work_item">
                                <div className="work_img">
                                <img src="images/work-1.jpg" alt="sample" />
                                <div className="work_img_inner">
                                    <a href="/"><i className="fa fa-link" /></a>
                                    <a className="venobox" data-gall="myGallery" href="images/work-1.jpg"><i className="fa fa-camera" /></a>
                                </div>
                                </div>
                                <div className="item_des">
                                <a href="/"><h3>Claritas Etia Processus</h3></a>
                                <p>Web Design</p>
                                </div>
                            </div>
                            </div>
                            <div className=" col-sm-6 col-lg-4 filtr-item" data-category="2, 5">
                            <div className="work_item">
                                <div className="work_img">
                                <img src="images/work-2.jpg" alt="sample" />
                                <div className="work_img_inner">
                                    <a href="/"><i className="fa fa-link" /></a>
                                    <a className="venobox" data-gall="myGallery" href="images/work-2.jpg"><i className="fa fa-camera" /></a>
                                </div>
                                </div>
                                <div className="item_des">
                                <a href="/"><h3>Quam Nutamus Farum</h3></a>
                                <p>Graphic Design, Mock-Up</p>
                                </div>
                            </div>
                            </div>
                            <div className=" col-sm-6 col-lg-4 filtr-item" data-category="1, 4">
                            <div className="work_item">
                                <div className="work_img">
                                <img src="images/work-3.jpg" alt="sample" />
                                <div className="work_img_inner">
                                    <a href="/"><i className="fa fa-link" /></a>
                                    <a className="venobox" data-gall="myGallery" href="images/work-3.jpg"><i className="fa fa-camera" /></a>
                                </div>
                                </div>
                                <div className="item_des">
                                <a href="/"><h3>Usus Legentis Videntur</h3></a>
                                <p>Photography, Holiday</p>
                                </div>
                            </div>
                            </div>
                            <div className=" col-sm-6 col-lg-4 filtr-item" data-category="1, 3">
                            <div className="work_item">
                                <div className="work_img">
                                <img src="images/work-4.jpg" alt="sample" />
                                <div className="work_img_inner">
                                    <a href="/"><i className="fa fa-link" /></a>
                                    <a className="venobox" data-gall="myGallery" href="images/work-4.jpg"><i className="fa fa-camera" /></a>
                                </div>
                                </div>
                                <div className="item_des">
                                <a href="/"><h3>Quam Nutamus Farum</h3></a>
                                <p>Graphic Design, Mock-Up</p>
                                </div>
                            </div>
                            </div>
                            <div className=" col-sm-6 col-lg-4 filtr-item" data-category="3, 4">
                            <div className="work_item">
                                <div className="work_img">
                                <img src="images/work-5.jpg" alt="sample" />
                                <div className="work_img_inner">
                                    <a href="/"><i className="fa fa-link" /></a>
                                    <a className="venobox" data-gall="myGallery" href="images/work-5.jpg"><i className="fa fa-camera" /></a>
                                </div>
                                </div>
                                <div className="item_des">
                                <a href="/"><h3>Claritas Etia Processus</h3></a>
                                <p>Web Design</p>
                                </div>
                            </div>
                            </div>
                            <div className=" col-sm-6 col-lg-4 filtr-item" data-category="2, 4">
                            <div className="work_item">
                                <div className="work_img">
                                <img src="images/work-6.jpg" alt="sample" />
                                <div className="work_img_inner">
                                    <a href="/"><i className="fa fa-link" /></a>
                                    <a className="venobox" data-gall="myGallery" href="images/work-6.jpg"><i className="fa fa-camera" /></a>
                                </div>
                                </div>
                                <div className="item_des">
                                <a href="/"><h3>Quam Nutamus Farum</h3></a>
                                <p>Graphic Design, Mock-Up</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="clearfix" />
                        <div className="work_load_btn">
                            <a href="/" className="multi_button">Load More</a>
                        </div>
                        </div>
                    </div>
                    </div>
            </section>
        )
    }
}

export default WorkList