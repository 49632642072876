import React, { Component } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import TeamBgImg from '../images/team-bg.jpg';

let TeamSection = styled.section`
background-image: url(${TeamBgImg});
`

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <i
            className={className, 'fa fa-angle-left team_arrow_left'}
            style={{ ...style,}}
            onClick={onClick}
        />
    );
}
function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <i
            className={className, 'fa fa-angle-right team_arrow_right'}
            style={{ ...style,}}
            onClick={onClick}
        />
    );
}


class TeamList extends Component {
    render(){
        const settings = {
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            infinite: true,
            dots: false,
            autoplay: true,
            autoplaySpeed: 2000,
            prevArrow: <PrevArrow style={{color: 'red'}} />,
            nextArrow: <NextArrow />,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        infinite: true,
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false
                    }
    
                }
            ]
        };
        return (
            <TeamSection id="team_part">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                        <div className="section_head team_head">
                            <h2><span>Meet Our</span> team</h2>
                        </div>
                        </div>
                        <div className="col-12 team_member_content">
                        <Slider {...settings} className="team_members">
                            <div className="slider-item">
                            <div className="team_memb">
                                <img src="images/team-1.jpg" alt="Team Member" />
                                <div className="team_memb_info">
                                <h3>Robin Topper</h3>
                                <p>Creative Director</p>
                                </div>
                                <div className="team_social">
                                <a href="/" target="_blank"><i className="fab fa-facebook-f" /></a>
                                <a href="/" target="_blank"><i className="fab fa-twitter" /></a>
                                <a href="/" target="_blank"><i className="fab fa-skype" /></a>
                                </div>
                            </div>
                            </div>
                            <div className="slider-item">
                            <div className="team_memb">
                                <img src="images/team-2.jpg" alt="Team Member" />
                                <div className="team_memb_info">
                                <h3>Alex Roren</h3>
                                <p>Creative Director</p>
                                </div>
                                <div className="team_social">
                                <a href="/" target="_blank"><i className="fab fa-facebook-f" /></a>
                                <a href="/" target="_blank"><i className="fab fa-twitter" /></a>
                                <a href="/" target="_blank"><i className="fab fa-skype" /></a>
                                </div>
                            </div>
                            </div>
                            <div className="slider-item">
                            <div className="team_memb">
                                <img src="images/team-3.jpg" alt="Team Member" />
                                <div className="team_memb_info">
                                <h3>Steve Jobs</h3>
                                <p>Creative Director</p>
                                </div>
                                <div className="team_social">
                                <a href="/" target="_blank"><i className="fab fa-facebook-f" /></a>
                                <a href="/" target="_blank"><i className="fab fa-twitter" /></a>
                                <a href="/" target="_blank"><i className="fab fa-skype" /></a>
                                </div>
                            </div>
                            </div>
                            <div className="slider-item">
                            <div className="team_memb">
                                <img src="images/team-2.jpg" alt="Team Member" />
                                <div className="team_memb_info">
                                <h3>Jammy JR</h3>
                                <p>Creative Director</p>
                                </div>
                                <div className="team_social">
                                <a href="/"><i className="fab fa-facebook-f" /></a>
                                <a href="/"><i className="fab fa-twitter" /></a>
                                <a href="/"><i className="fab fa-skype" /></a>
                                </div>
                            </div>
                            </div>
                        </Slider>
                        </div>
                    </div>
                    </div>
            </TeamSection>
        )
    }
}

export default TeamList