import React, { useState } from 'react';
import styled from 'styled-components';
import FsLightbox from 'fslightbox-react';


import AboutBgImg from '../images/about.jpg';

let AboutCard = styled.div`
    background-image: url(${AboutBgImg});
`

const About = () => {
    const [toggler, setToggler] = useState(false);

    return (
        <section id="about_part">
            <div className="container">
            <div className="row flex-md-row-reverse">
                <div className="col-md-6">
                    <div className="about_right section_head">
                        <h2><span>Some words</span> about us</h2>
                        <p>We love building and rebuilding brands through our specific skills. Using colour, fonts, and illustration, we brand companies in a way they will never forget.We love building and rebuilding brands through our specific skills. Using colour, fonts, and illustration, we brand companies...</p>
                        <a href="/" className="multi_button">Read More</a>
                        <ul>
                        <li><i className="far fa-dot-circle" aria-hidden="true" /> <span>Mission -</span> We deliver uniqueness and quality</li>
                        <li><i className="far fa-dot-circle" aria-hidden="true" /><span> Skills -</span> Delivering fast and excellent results</li>
                        <li><i className="far fa-dot-circle" aria-hidden="true" /><span> Clients -</span> Satisfied clients thanks to our experience</li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-6">
                    <AboutCard className="about_left text-center">
                        <div className="about_left_inner">
                        <a onClick={(e) => {e.preventDefault();return setToggler(!toggler)}} href="https://www.youtube.com/watch?v=S7wCAquf9Us"><i className="fab fa-youtube" /></a>
                        <FsLightbox
                            toggler={toggler}
                            sources={[
                                'https://www.youtube.com/watch?v=S7wCAquf9Us'
                            ]}
                        />
                        <h3>Spirit Video Presentation</h3>
                        <p>Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius, qui sequitur mutationem consuetudium.</p>
                        </div>
                    </AboutCard>
                </div>
            </div>
            </div>
        </section>
    )
};

export default About