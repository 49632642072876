import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'slick-carousel/slick/slick.css';
import './index.scss';
import App from './App';

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
