import React, { Component } from 'react';


class ServiceList extends Component {
    render(){
        return (
            <section id="service_part">
                <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center">
                    <div className="section_head service_head">
                        <h2><span>take a look at</span> our services</h2>
                        <p>Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium.</p>
                    </div>
                    </div>
                    <div className="clearfix" />
                    <div className="service_bottom row mx-0 text-center">
                    <div className="col-md-4 col-sm-6">
                        <div className="service_item">
                        <i className="fa fa-code" />
                        <a href="/"><h3>Web Design</h3></a>
                        <p>Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim.</p>
                        <a href="/" className="ser_btn">Read More <i className="fa fa-arrow-right" /></a>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="service_item">
                        <i className="fa fa-laptop" />
                        <a href="/"><h3>Web Development</h3></a>
                        <p>Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim.</p>
                        <a href="/" className="ser_btn">Read More <i className="fa fa-arrow-right" /></a>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="service_item">
                        <i className="fab fa-deviantart" />
                        <a href="/"><h3>PHP</h3></a>
                        <p>Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim.</p>
                        <a href="/" className="ser_btn">Read More <i className="fa fa-arrow-right" /></a>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="service_item">
                        <i className="fab fa-app-store-ios" />
                        <a href="/"><h3>APPS</h3></a>
                        <p>Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim.</p>
                        <a href="/" className="ser_btn">Read More <i className="fa fa-arrow-right" /></a>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="service_item">
                        <i className="fa fa-leaf" />
                        <a href="/"><h3>Branding</h3></a>
                        <p>Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim.</p>
                        <a href="/" className="ser_btn">Read More <i className="fa fa-arrow-right" /></a>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="service_item">
                        <i className="fas fa-phone-square-alt" />
                        <a href="/"><h3>24/7 Support</h3></a>
                        <p>Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim.</p>
                        <a href="/" className="ser_btn">Read More <i className="fa fa-arrow-right" /></a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
        )
    }
}

export default ServiceList