import React, { Component} from 'react';

import styled from 'styled-components';
import SkillBgImage from '../images/skill-bg.jpeg';

let SkillSection = styled.section`
background-image: url(${SkillBgImage});
`

let progressbarValue = () => {
    let progressWrap = document.querySelectorAll('.progress');
    // console.log(progressWrap)
    progressWrap.forEach((el, i) => {
        let progressBar = el.children[0];
        progressBar.style.width = `${progressBar.getAttribute('aria-valuenow')}%`

    });
}

class Skill extends Component {
    componentDidMount(){
        progressbarValue();
    }

    render() {
        return (
            <SkillSection id="skill_part" data-bg-image="">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                        <div className="section_head skill_head text-center">
                            <h2><span>Our </span>Skill <span>And Expertise</span></h2>
                            <p>Recusandae, quod perspiciatis, at dicta enim similique earum temporibus iusto accusamus dolore adipisci veritatis nulla distinctio quisquam, nesciunt reprehenderit itaque nihil culpa!</p>
                        </div>
                        </div>
                        <div className="col-md-5">
                        <div className="skill_left">
                            <h3>Why choose us</h3>
                            <p>Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius. Claritas est etiam processus dynamicus</p>
                            <ul>
                            <li><i className="far fa-dot-circle" aria-hidden="true" /> We design uniqueness and quality</li>
                            <li><i className="far fa-dot-circle" aria-hidden="true" />Delivering fast and excellent results</li>
                            <li><i className="far fa-dot-circle" aria-hidden="true" />Tons of useful widgets &amp; shortcodes</li>
                            <li><i className="far fa-dot-circle" aria-hidden="true" />Easy to use layout visual builder</li>
                            </ul>
                        </div>
                        </div>
                        <div className="col-md-7">
                        <div className="skill_right">
                            <h3>Professionals with the relevant skills to match</h3>
                            <div className="barWrapper">
                            <span className="progressText"><b>Design</b></span>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" aria-valuenow={85} aria-valuemin={0} aria-valuemax={100}>
                                    <span className="popOver" data-toggle="tooltip" data-placement="top" title="85%"></span>
                                </div>
                            </div>
                            </div>
                            <div className="barWrapper">
                            <span className="progressText"><b>Development</b></span>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" aria-valuenow={95} aria-valuemin={0} aria-valuemax={100}>
                                    <span className="popOver" data-toggle="tooltip" data-placement="top" title="95%"> </span>
                                </div>
                            </div>
                            </div>
                            <div className="barWrapper">
                            <span className="progressText"><b>PHP</b></span>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" aria-valuenow={90} aria-valuemin={0} aria-valuemax={100}>
                                    <span className="popOver" data-toggle="tooltip" data-placement="top" title="90%"> </span>
                                </div>
                            </div>
                            </div>
                            <div className="barWrapper">
                            <span className="progressText"><b>Apps</b></span>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" aria-valuenow={80} aria-valuemin={0} aria-valuemax={100}>
                                    <span className="popOver" data-toggle="tooltip" data-placement="top" title="80%"></span>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
            </SkillSection>
        )
    }
};

export default Skill