import React from 'react';
import Scrollchor from 'react-scrollchor';

import LogoImage from '../../images/logo.png';

let navbarSticky = () => {
    	/* back to top button*/
	// var HtmlBody = $('html, body'),
    // backtotop = $("#backtotop");

    // let windo = window;
    // var my_nav = document.getElementsByClassName('my_nav');

    // windo.addEventListener('scroll', function () {
    //     var scrltop = windo.scrollY;
    //     console.log(my_nav)
    //     if (scrltop > 400) {
    //         my_nav.classList += " nav_scrl";
    //     } else {
    //         my_nav.classList -= " nav_scrl";
    //     }
    //     // Back to top button

    //     // if (scrltop > 200) {
    //     //     backtotop.fadeIn();
    //     // } else {
    //     //     backtotop.fadeOut();
    //     // }
    // });
}

const Header = () => {
    navbarSticky()
    return (
        <React.Fragment>
            {/*  Pre-Loader Start */}
            {/* <div id="preloader">
                <div className="pre_img">
                <img src="images/pre-loader.gif" alt="" />
                </div>
            </div> */}
            {/*  Pre-Loader End */}
            {/*  Header Part Start  */}
            <header id="header_part">
                <nav className="navbar navbar-expand-lg navbar-default my_nav">
                <div className="container px-0">
                    {/* Brand and toggle get grouped for better mobile display */}
                    <a className="navbar-brand main_logo" href="/">
                        <img src={LogoImage} alt="LOGO" />
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#nav_list" aria-controls="nav_list" aria-expanded="false" aria-label="Toggle navigation">
                    <svg className="bi bi-list" viewBox="0 0 16 16" fill="currentColor">
                        <path fillRule="evenodd" d="M2.5 11.5A.5.5 0 013 11h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 7h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 3h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5z" clipRule="evenodd" />
                    </svg>
                    </button>
                    {/* Collect the nav links, forms, and other content for toggling */}
                    <div className="collapse navbar-collapse" id="nav_list">
                    <ul className="nav navbar-nav ml-auto">
                        <li className="active"><Scrollchor to="#header_part">Home</Scrollchor></li>
                        <li><Scrollchor to="#about_part">About</Scrollchor></li>
                        <li><Scrollchor to="#service_part">Service</Scrollchor></li>
                        <li><Scrollchor to="#work_part">Portfolio</Scrollchor></li>
                        <li><Scrollchor to="#testimonal_part">Testimonials</Scrollchor></li>
                        <li><Scrollchor to="#blog_part">Blog</Scrollchor></li>
                        <li><Scrollchor to="#contact_part">Contact</Scrollchor></li>
                        <li></li>
                    </ul>
                    </div>
                    {/* /.navbar-collapse */}
                </div>
                {/* /.container */}
                </nav>
            </header>
            {/*  Header Part End  */}
        </React.Fragment>
    )
}

export default Header