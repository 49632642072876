import React, { Component } from 'react';
import Slider from 'react-slick';

class TestimonialList extends Component {
    
    render(){
        const settings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            infinite: true,
            dots: true,
            autoplay: true,
            autoplaySpeed: 2000
        };
        return (
            <section id="testimonal_part">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                        <div className="section_head text-center">
                            <h2><span>What our Clients Say ?</span></h2>
                            <p>Our beloved customer's comment on us</p>
                        </div>
                        <div className="testimonal_content">
                            <Slider {...settings} className="client_testi">
                                <div className="slider-item">
                                    <div className="client_testi_item">
                                    <div className="col-md-4 col-lg-3 offset-xl-2 offset-lg-1">
                                        <div className="member_img">
                                        <img src="images/clint-1.jpg" alt="Clint" />
                                        </div>
                                    </div>
                                    <div className="col-md-8 col-lg-7">
                                        <div className="member_info">
                                        <h3>NIKKA OKAFA</h3>
                                        <h4>CEO at Super Duper Company</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Numquam blanditiis voluptas, laudantium, facere sunt deleniti assumenda accusantium nesciunt nemo quo pariatur</p>
                                        <ul>
                                            <li><a href="/"><i className="fab fa-facebook-f"  /></a></li>
                                            <li><a href="/"><i className="fab fa-twitter" /></a></li>
                                            <li><a href="/"><i className="fab fa-linkedin-in" /></a></li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="slider-item">
                                    <div className="client_testi_item">
                                    <div className="col-md-4 col-lg-3 offset-xl-2 offset-lg-1">
                                        <div className="member_img">
                                        <img src="images/clint-2.jpg" alt="Clint Images" />
                                        </div>
                                    </div>
                                    <div className="col-md-8 col-lg-7">
                                        <div className="member_info">
                                        <h3>STEVE JOBS</h3>
                                        <h4>CEO at Iphone INC.</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Numquam blanditiis voluptas, laudantium, facere sunt deleniti assumenda accusantium nesciunt nemo quo pariatur</p>
                                        <ul>
                                            <li><a href="/"><i className="fab fa-facebook-f"  /></a></li>
                                            <li><a href="/"><i className="fab fa-twitter" /></a></li>
                                            <li><a href="/"><i className="fab fa-linkedin-in" /></a></li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="slider-item">
                                <div className="client_testi_item">
                                <div className="col-md-4 col-lg-3 offset-xl-2 offset-lg-1">
                                    <div className="member_img">
                                    <img src="images/clint-3.jpg" alt="Clint Images" />
                                    </div>
                                </div>
                                <div className="col-md-8 col-lg-7">
                                    <div className="member_info">
                                    <h3>LATHEM JOHNS</h3>
                                    <h4>CEO at Entrepreneur Company</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Numquam blanditiis voluptas, laudantium, facere sunt deleniti assumenda accusantium nesciunt nemo quo pariatur</p>
                                    <ul>
                                        <li><a href="/"><i className="fab fa-facebook-f"  /></a></li>
                                        <li><a href="/"><i className="fab fa-twitter" /></a></li>
                                        <li><a href="/"><i className="fab fa-linkedin-in" /></a></li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </Slider>
                            {/*
                                <div className="testi_arrow">
                                    <i className="fa fa-angle-left testi_prev"></i>
                                    <i className="fa fa-angle-right testi_next"></i>
                                </div>
            */}
                        </div>
                        </div>
                    </div>
                    </div>
            </section>
        )
    }
}

export default TestimonialList