import React from 'react';
import styled from 'styled-components';
import AchiBgImage from '../images/counter-bg.jpg';

let ArchiveSection = styled.section`
background-image: url(${AchiBgImage});
`

const Achievement = () => {
    return (
    <ArchiveSection id="achivement_part">
        <div className="container">
        <div className="counter_content">
            <div className="row text-center">
            <div className="col-sm-6 col-md-3">
                <div className="counter_item">
                <i className="fas fa-puzzle-piece" />
                <h3 className="counting">2400</h3>
                <p>Project Complated</p>
                </div>
            </div>
            <div className="col-sm-6 col-md-3">
                <div className="counter_item">
                <i className="fas fa-handshake" />
                <h3 className="counting">1600</h3>
                <p>Clients Satisfaction</p>
                </div>
            </div>
            <div className="col-sm-6 col-md-3">
                <div className="counter_item">
                <i className="fa fa-trophy" />
                <h3 className="counting">200</h3>
                <p>Awards Wins</p>
                </div>
            </div>
            <div className="col-sm-6 col-md-3">
                <div className="counter_item connter_last">
                <i className="fa fa-users" />
                <h3 className="counting">1000</h3>
                <p>Users</p>
                </div>
            </div>
            </div>
            <div className="clearfix" />
        </div>
        </div>
    </ArchiveSection>
    )
};

export default Achievement