import React from 'react';
import Scrollchor from 'react-scrollchor';

import LogoImage from '../../images/logo.png';


const Footer = () => {
    return (
        <React.Fragment>
            {/*  footer Part Start  */}
            <footer id="footer_part">
                {/*Footer Widgets start*/}
                <div className="footer_widgets">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-6">
                        <div className="widgets widg_1">
                        <a href="index.html">
                            <img src={LogoImage} alt="" />
                        </a>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro hic saepe ipsum id, sed tempore expedita earum aperiam suscipit quis blanditiis iure et quae.</p>
                        <div className="office_des">
                            <ul>
                            <li><i className="fas fa-map-marker-alt" /> 2101 Church Ave, Brooklyn, NY 11226</li>
                            <li><i className="far fa-clock" /> Monday - Friday : 9 AM - 7 PM</li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6">
                        <div className="widgets widg_2">
                        <h3>Information</h3>
                        <ul>
                            <li><a href="/"><i className="fa fa-long-arrow-right" />About Us</a></li>
                            <li><a href="/"><i className="fa fa-long-arrow-right" />Delivery Information</a></li>
                            <li><a href="/"><i className="fa fa-long-arrow-right" />Privacy Policy</a></li>
                            <li><a href="/"><i className="fa fa-long-arrow-right" />Terms &amp; Conditions</a></li>
                            <li><a href="/"><i className="fa fa-long-arrow-right" />FAQ</a></li>
                        </ul>
                        </div>
                        <div className="clearfix visible-sm" />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className="widgets widg_3">
                        <h3>Our Twitter</h3>
                        <div className="tweets">
                            <ul>
                            <li>
                                <a href="/" target="_blank">@Spirit</a> Cum soluta nobis eleifend option congue nihil imperdiet doming
                                <span>3 mins ago</span>
                            </li>
                            <li>
                                <a href="/" target="_blank">#envato</a> Mirum est notare quam littera gothica, quam nunc putamus parum
                                <span>5 hours ago</span>
                            </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className="widgets widg_4">
                        <h3>Subcribe Newsletter</h3>
                        <p>Join awesome subscribers and update yourself with our exclusive news.</p>
                        <form>
                            <input type="email" placeholder="Enter Your Email" required />
                            <button>Subcribe Now</button>
                        </form>
                        <div className="bottom_social">
                            <ul className="text-center">
                            <li><a href="/" target="_blank"><i className="fab fa-facebook-f" /></a></li>
                            <li><a href="/" target="_blank"><i className="fab fa-twitter" /></a></li>
                            <li><a href="/" target="_blank"><i className="fab fa-instagram" /></a></li>
                            <li><a href="/" target="_blank"><i className="fab fa-skype" /></a></li>
                            <li><a href="/" target="_blank"><i className="fab fa-linkedin-in" /></a></li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*Footer Bottom start*/}
                <div id="footet_bottom">
                <div className="container">
                    <div className="footer_main">
                    <div className="row">
                        <div className="col-md-5">
                        <div className="footer_left">
                            <ul>
                            <li>Copyright © 2020 | <a href="https://themeforest.net/item/spirit-corporate-business-template/20547499">SPIRIT</a></li>
                            </ul>
                        </div>
                        </div>
                        <div className="col-md-7">
                        <div className="footer_right text-md-right">
                            <ul>
                            <li>Design by <a href="https://techincent.com" target="_blank" rel="noopener noreferrer">TechIncent</a></li>
                            <li>Only On <a href="https://themeforest.net/user/pollexe/portfolio">Envato Market</a></li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </footer>
            {/*  Footer Part End  */}
            {/*  Bact to Top button start */}
            <Scrollchor to="html-body" id="backtotop">
                <i className="fa fa-arrow-up backtotop_btn" />
            </Scrollchor>
            {/*  Bact to Top button End */}
        </React.Fragment>
    )
}

export default Footer