import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const Marker = () => <div><i className="fas fa-map-marker-alt" style={{color: 'red', fontSize: '30px'}}></i></div>;

class ContactUs extends Component {
    static defaultProps = {
        center: {
          lat: 43.041216,
          lng: -76.131034
        },
        zoom: 18
    };

    render(){
        return (
            <section id="contact_part">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                        <div className="section_head contact_head text-center">
                            <h2>Contact <span>us</span></h2>
                        </div>
                        </div>
                        <div className="col-md-5 col-sm-12 order-md-3 mb-4 mb-md-0">
                        <div className="contact_info">
                            <ul>
                            <li className="c_call">
                                <i className="fa fa-phone" />
                                <aside>
                                <h3>Give us a Call</h3>
                                <a href="callto:+88018100001">+880-(181)-00001</a>
                                </aside>
                            </li>
                            <li className="c_mail">
                                <i className="far fa-envelope" />
                                <aside>
                                <h3>Send a Mail</h3>
                                <a href="mailto:info@mail.com">info@gmail.com</a>
                                </aside>
                            </li>
                            </ul>
                        </div>
                        </div>
                        <div className="col-md-7 col-sm-12">
                        <div className="contact_form">
                            <form className="form-group">
                            <div className="row">
                                <div className="col-md-6">
                                <input type="text" className="form-control c_name" placeholder="Full Name *" required />
                                </div>
                                <div className="col-md-6">
                                <input type="email" className="form-control c_email" placeholder="Email *" required />
                                </div>
                                <div className="col-md-6">
                                <input type="text" className="form-control c_sub" placeholder="Subject *" required />
                                </div>
                                <div className="col-md-6">
                                <input type="number" className="form-control c_num" placeholder="Mobile *" required />
                                </div>
                                <div className="col-sm-12">
                                <textarea className="form-control c_textarea" placeholder="Your Message" defaultValue={""} />
                                </div>
                                <div className="col-sm-12 text-center">
                                <button className="btn btn-primary">Submit Now</button>
                                </div>
                            </div>
                            </form>
                        </div>
                        </div>
                        <div className="clearfix" />
                    </div>
                </div>
                <div id="map_part">
                    <div id="map" className="map_part">
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: 'AIzaSyCm8veVh4ipYx0T0217Njyu1zPiwm60f3U' }}
                            defaultCenter={this.props.center}
                            defaultZoom={this.props.zoom}
                            
                        >
                            <Marker
                                lat={43.041216}
                                lng={-76.131034}
                            />
                        </GoogleMapReact>
                    </div>
                </div>
            </section>
        )
    }
}

export default ContactUs