import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import NotFoundBgImage from '../images/not-found-bg.jpg'

let Section = styled.section`{
    background: url(${NotFoundBgImage}) no-repeat center;
    background-size: cover;
    position: relative;
    &:after{
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0,0,0,.85);
        z-index: 1;
    }
}`

const NotFound = () => {
    let Row = styled.div`{
        height: 100vh;
        width: 100%;
        z-index: 2;
        position: relative;
    }`

    return (
        <Section>
            <div className="container">
                <Row className="d-flex flex-column flex-wrap align-items-center justify-content-center">
                    <h2 className="text-white">Page Not Found !</h2>
                    <Link className="btn btn-info mt-5" to="/">Back To Home</Link>
                </Row>
            </div>
        </Section>
    )
}

export default NotFound