import React, { Component } from 'react';

import Banner from '../components/Banner';
import About from '../components/About';
import ServiceList from '../components/ServiceList';
import Achievement from '../components/Achievement';
import WorkList from '../components/WorkList';
import Skill from '../components/Skill';
import TestimonialList from '../components/TestimonialList';
import TeamList from '../components/TeamList';
import BlogList from '../components/BlogList';
import ContactUs from '../components/ContactUs';

class Home extends Component {
    render (){
        return (
            <div>
                <Banner />
                <About />
                <ServiceList />
                <Achievement />
                <WorkList />
                <Skill />
                <TestimonialList />
                <TeamList />
                <BlogList />
                <ContactUs />
            </div>
        );
    }
}

export default Home